import { PROCEDURE_SETTINGS_TYPES, PROCEDURE_SETTING_PROPS_DICTIONARY } from '@configProcedures';
import { ProcedureTypeEnum } from '@enums';
import { ChevronRight, ProcedureToggleIcon } from '@icons';
import { ProcedureSettingsValueType } from '@models';
import { getProcedureSettingsAsString, toNumber } from '@procedures';
import { CoursePackage, CoursePackageType } from '@sliceProcedures';
import { Button, Checkbox, Collapse, Form, Input, InputNumber, Radio, Select, message } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CourseDevice } from 'src/enums/course-device.enum';
import {
    CourseProceduresList,
    CoursesDevices,
    GET_DEFAULT_PROCEDURE_PARAMS,
    OcclusionList,
    OcclusionType,
} from '../../../../../../../COURSES_CONFIG';
import styles from './CourseStep.module.scss';

const { Option } = Select;
const { Panel } = Collapse;

export interface IProcedureFormItem {
    procedure: ProcedureTypeEnum;
    selected: boolean;
    ou?: Partial<ProcedureSettingsValueType>;
    od?: Partial<ProcedureSettingsValueType>;
    os?: Partial<ProcedureSettingsValueType>;
    occlusionEye?: 'od' | 'os';
}

export interface IOcclusionDay {
    day?: number;
    type?: 'od' | 'os';
}

export interface ICourseForm {
    planDefinitionID?: CoursePackageType;
    courseNotRecommended?: boolean;
    durationInDays?: number;
    needGlasses?: 'in_glasses' | 'without_glasses' | 'mkl';
    targetEye?: 'ou' | 'od' | 'os';
    procedures?: IProcedureFormItem[];
    occlusionType?: OcclusionType;
    occlusionSettings?: IOcclusionDay[];
    needGlassesWithoutDyo?: boolean;
    glassesWithoutDyoSize?: 's' | 'm' | 'l';
    deviceDefinitionIDs?: CourseDevice[];
    extended?: boolean;
}

interface ICourseStepProps {
    initialValues?: ICourseForm;
    disabled?: boolean;
    modalMode?: boolean;

    onSubmit: (formValue: ICourseForm) => void;
    onFormChange: (formValue: ICourseForm) => void;
}

export const CourseStep = ({ onSubmit, onFormChange, initialValues, disabled, modalMode = false }: ICourseStepProps) => {
    const { t } = useTranslation();
    const [form] = useForm();

    const [proceduresList, setProceduresList] = useState<IProcedureFormItem[]>([]);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const [procedureFormErrors, setProcedureFormErrors] = useState<any>([]);

    useEffect(() => {
        // todo not ok to mutate this, but works
        if (initialValues) {
            initialValues.procedures = initialValues.procedures || proceduresList;
            // set procedures list
            if (initialValues.planDefinitionID) {
                const procedures = initialValues.procedures || getProceduresForCourse(initialValues.planDefinitionID);

                form.setFieldValue('procedures', procedures);
                setProceduresList(procedures);

                form.setFieldValue('deviceDefinitionIDs', CoursesDevices[initialValues.planDefinitionID] || []);
            }
        }
    }, []);

    const formChangeHandler = (values: Partial<ICourseForm>, allValues: ICourseForm) => {
        if (values.planDefinitionID) {
            const procedures = getProceduresForCourse(values.planDefinitionID);

            form.setFieldValue('procedures', procedures);
            setProceduresList(procedures);

            form.setFieldValue('deviceDefinitionIDs', CoursesDevices[values.planDefinitionID] || []);
        }

        if (values.procedures) {
            const changedProcedureIndex = values.procedures.findIndex(Boolean);

            if (values.procedures[changedProcedureIndex]?.selected) {
                const targetProcedure = allValues.procedures?.[changedProcedureIndex];
                // set default values, if there are not
                if (targetProcedure && !(!!targetProcedure.ou || !!targetProcedure.od || !!targetProcedure.os)) {
                    // // we set for all, and filter it before submit
                    form.setFieldValue(['procedures', changedProcedureIndex], {
                        ...targetProcedure,
                        ou: GET_DEFAULT_PROCEDURE_PARAMS(targetProcedure.procedure),
                        od: GET_DEFAULT_PROCEDURE_PARAMS(targetProcedure.procedure),
                        os: GET_DEFAULT_PROCEDURE_PARAMS(targetProcedure.procedure),
                    });
                }
            }

            // lensFrom and lensTo - related fields. We need to validate all fields (when one of the related was changed)
            form.validateFields();
        }

        form.validateFields();
        setTimeout(() => {
            const procedureErrors: any = [];
            form.getFieldsError().forEach((item) => {
                if (item.errors && item.errors.length > 0) {
                    procedureErrors[item.name[1]] = true;
                }
            });
            setProcedureFormErrors(procedureErrors);
            setIsFormValid(!form.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0));
        });

        onFormChange(allValues);
    };

    const formSubmitHandler = (values: ICourseForm) => {
        if (values.procedures?.every((x) => !x.selected)) {
            message.error(t("course_step.no_procedures_selected"));
            return;
        }

        onSubmit(values);
    };

    const renderSettingsString = (procedureIndex: number, type: string) => {
        const amblyopiaIncluded = targetEyeValue === 'ou' && !!occlusionTypeValue && occlusionTypeValue !== 'without';
        const odSettingsString = getProcedureSettingsAsString(proceduresValue?.[procedureIndex].od, amblyopiaIncluded ? [] : ['amblyopia']);
        const osSettingsString = getProcedureSettingsAsString(proceduresValue?.[procedureIndex].os, amblyopiaIncluded ? [] : ['amblyopia']);
        const ouSettingsString = getProcedureSettingsAsString(proceduresValue?.[procedureIndex].ou, amblyopiaIncluded ? [] : ['amblyopia']);

        return (
            <p className={styles.settingsLabel}>
                {type === ProcedureTypeEnum.BIFIXATION_RECOVERY ? (
                    <>
                        {(proceduresValue?.[procedureIndex].occlusionEye
                             || proceduresValue?.[procedureIndex]?.od?.occlusionEye
                             || proceduresValue?.[procedureIndex]?.os?.occlusionEye
                             || proceduresValue?.[procedureIndex]?.ou?.occlusionEye
                         ) ? (
                            proceduresValue?.[procedureIndex].occlusionEye && targetEyeValue === 'ou' ? (
                                <>
                                    {t(`procedureSettingsWizard.occlusionEye.shortLabel`)}:{' '}
                                    {t(`procedureSettingsWizard.occlusionEye.values.${proceduresValue?.[procedureIndex].occlusionEye}`)}
                                </>
                            ) : (
                                <>
                                    {targetEyeValue === 'os' && <>{osSettingsString || t("course_step.no_settings")}</>}
                                    {targetEyeValue === 'od' && <>{odSettingsString || t("course_step.no_settings")}</>}
                                </>
                            )
                        ) : (
                            <>{t("course_step.no_settings")}</>
                        )}
                    </>
                ) : targetEyeValue === 'ou' ? (
                    occlusionTypeValue === 'without' ? (
                        <>{ouSettingsString || t("course_step.no_settings")}</>
                    ) : (
                        <>
                            {odSettingsString && <>OD: {odSettingsString}</>}
                            {osSettingsString && (
                                <>
                                    <br />
                                    OS: {osSettingsString}
                                </>
                            )}
                            {!odSettingsString && !osSettingsString && <>{t("course_step.no_settings")}</>}
                        </>
                    )
                ) : targetEyeValue === 'od' ? (
                    <>{odSettingsString || t("course_step.no_settings")}</>
                ) : (
                    <>{osSettingsString || t("course_step.no_settings")}</>
                )}
            </p>
        );
    };

    // Ant doesn't rerender the whole form  if some field changes (even with shouldUpdate)
    // https://nanxiaobei.medium.com/watch-a-more-elegant-way-to-monitor-antd-form-field-changes-7c9b12457d67
    const selectedCourseValue = useWatch('planDefinitionID', form);
    const courseNotRecommendedValue = useWatch('courseNotRecommended', form);

    const needGlassesValue = useWatch('needGlasses', form);
    const targetEyeValue = useWatch('targetEye', form);
    const occlusionTypeValue = useWatch('occlusionType', form);
    const occlusionSettingsValue = useWatch('occlusionSettings', form);
    const proceduresValue = useWatch<IProcedureFormItem[]>('procedures', form);
    const needGlassesWithoutDyoValue = useWatch('needGlassesWithoutDyo', form);
    const glassesWithoutDyoSizeValue = useWatch('glassesWithoutDyoSize', form);

    return (
        <div className={styles.wrapper}>
            {!modalMode && <h2 className={styles.title}>{t('consultationSession.courseStep.title')}</h2>}

            <Form
                form={form}
                autoComplete="off"
                initialValues={initialValues}
                layout="vertical"
                requiredMark={false}
                onFinish={formSubmitHandler}
                onValuesChange={formChangeHandler}
                disabled={disabled}
            >
                {!modalMode ? (
                    <Form.Item className={styles.formGroup} label={t("course_step.recommended_course")} name="planDefinitionID">
                        <Select className="w-100" disabled={disabled || courseNotRecommendedValue || modalMode}>
                            {Object.keys(CoursePackage).map((x) => (
                                <Option key={x} value={x}>
                                    {t('consultationSession.courseStep.courses.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : (
                    <Form.Item name="planDefinitionID" className="d-none">
                        <Input />
                    </Form.Item>
                )}

                {!modalMode && (
                    <div className={styles.checkboxGroup}>
                        <Form.Item name="courseNotRecommended" valuePropName="checked">
                            <Checkbox>{t("course_step.not_recommended_course")}</Checkbox>
                        </Form.Item>
                    </div>
                )}
                {!modalMode && initialValues?.planDefinitionID && (
                    <div className={styles.checkboxGroup}>
                        <Form.Item name="extended" valuePropName="checked">
                            <Checkbox>{t("course_step.extended_course")}</Checkbox>
                        </Form.Item>
                    </div>
                )}

                {!(selectedCourseValue && !courseNotRecommendedValue) && !disabled && (
                    <Button className="w-100 mt-4" type="primary" htmlType="submit" disabled={!courseNotRecommendedValue}>
                        {t("save")}
                    </Button>
                )}

                {selectedCourseValue && !courseNotRecommendedValue && (
                    <>
                        <p className={classNames(styles.groupLabel, modalMode && 'mt-0')}>{t("course_step.course_settings")}</p>
                        <div className={styles.formGrid22}>
                            <label>{t("course_step.duration")}</label>
                            <Form.Item className={styles.formGroup}>
                                <Form.Item name="durationInDays" noStyle>
                                    <InputNumber disabled={true} />
                                </Form.Item>
                                <span className="ant-form-text">{t("course_step.days")}</span>
                            </Form.Item>

                            <label>{t("course_step.glasses_required")}</label>
                            <Form.Item className={styles.formGroup} name="needGlasses" rules={[{ required: true, message: t("required_field") }]}>
                                <Radio.Group className={classNames(styles.radioGroup, disabled && styles.disabled)}>
                                    <label className={classNames(styles.radio, needGlassesValue === 'without_glasses' && styles.selected)}>
                                        <Radio value="without_glasses" className="d-none"></Radio>
                                        {t("course_step.without_glasses")}
                                    </label>
                                    <label className={classNames(styles.radio, needGlassesValue === 'in_glasses' && styles.selected)}>
                                        <Radio value="in_glasses" className="d-none"></Radio>
                                        {t("course_step.with_glasses")}
                                    </label>
                                    <label className={classNames(styles.radio, needGlassesValue === 'mkl' && styles.selected)}>
                                        <Radio value="mkl" className="d-none"></Radio>
                                        {t("course_step.mkl")}
                                    </label>
                                </Radio.Group>
                            </Form.Item>

                            <label>{t("course_step.what_eue")}</label>
                            <Form.Item className={styles.formGroup} name="targetEye" rules={[{ required: true, message: t("required_field") }]}>
                                <Radio.Group className={classNames(styles.radioGroup, disabled && styles.disabled)}>
                                    <label className={classNames(styles.radio, targetEyeValue === 'ou' && styles.selected)}>
                                        <Radio value="ou" className="d-none"></Radio>
                                        {t("course_step.ou")}
                                    </label>
                                    <label className={classNames(styles.radio, targetEyeValue === 'od' && styles.selected)}>
                                        <Radio value="od" className="d-none"></Radio>
                                        {t("course_step.od")}
                                    </label>
                                    <label className={classNames(styles.radio, targetEyeValue === 'os' && styles.selected)}>
                                        <Radio value="os" className="d-none"></Radio>
                                        {t("course_step.os")}
                                    </label>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        {targetEyeValue && (
                            <>
                                {targetEyeValue === 'ou' && (
                                    <>
                                        <p className={styles.groupLabel}>{t("course_step.occlusion")}</p>
                                        <Form.Item
                                            name="occlusionType"
                                            rules={[{ required: true, message: t("required_field") }]}
                                            className={styles.formGroup}
                                        >
                                            <Select placeholder={t("placeholder_select_occlusion")}>
                                                {OcclusionList.map((x) => (
                                                    <Option key={x} value={x}>
                                                        {t('consultationSession.courseStep.occlusionList.' + x)}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        {occlusionTypeValue === 'manually' && (
                                            <Form.List name="occlusionSettings">
                                                {(fields) => (
                                                    <div className={classNames(styles.formGrid22)}>
                                                        {fields.map((field) => (
                                                            <Fragment key={field.key}>
                                                                <label className={styles.label}>{t("afternoon")} {field.key + 1}</label>
                                                                <Form.Item
                                                                    name={[field.name, 'type']}
                                                                    rules={[{ required: true, message: t("required_field") }]}
                                                                >
                                                                    <Radio.Group
                                                                        className={classNames(styles.radioGroup, disabled && styles.disabled)}
                                                                    >
                                                                        <label
                                                                            className={classNames(
                                                                                styles.radio,
                                                                                occlusionSettingsValue?.[field.key]?.type === 'od' && styles.selected,
                                                                            )}
                                                                        >
                                                                            <Radio value="od" className="d-none"></Radio>
                                                                            OD
                                                                        </label>
                                                                        <label
                                                                            className={classNames(
                                                                                styles.radio,
                                                                                occlusionSettingsValue?.[field.key]?.type === 'os' && styles.selected,
                                                                            )}
                                                                        >
                                                                            <Radio value="os" className="d-none"></Radio>
                                                                            OS
                                                                        </label>
                                                                    </Radio.Group>
                                                                </Form.Item>
                                                            </Fragment>
                                                        ))}
                                                    </div>
                                                )}
                                            </Form.List>
                                        )}
                                    </>
                                )}

                                {!!proceduresList.length && (
                                    <>
                                        <p className={styles.groupLabel}>{t("course_step.procedures_set")}</p>

                                        <Form.List name="procedures">
                                            {(fields) => (
                                                <Collapse
                                                    className={styles.proceduresList}
                                                    defaultActiveKey={[]}
                                                    expandIconPosition="end"
                                                    expandIcon={({ isActive }) => (
                                                        <ProcedureToggleIcon style={{ transform: `rotate(${isActive ? -180 : 0}deg)` }} />
                                                    )}
                                                    collapsible="icon"
                                                >
                                                    {fields.map((field) => {
                                                        const procedure = proceduresList[field.key].procedure;

                                                        const selected = proceduresValue?.[field.key]?.selected;
                                                        return (
                                                            <Panel
                                                                key={field.key}
                                                                header={
                                                                    <Form.Item name={[field.name, 'selected']} valuePropName="checked">
                                                                        <Checkbox>
                                                                            <p
                                                                                className={classNames(
                                                                                    styles.panelLabel,
                                                                                    !selected && styles.inactive,
                                                                                )}
                                                                            >
                                                                                {t('procedures.list.' + procedure)}
                                                                                {procedureFormErrors[field.name] && (
                                                                                    <span style={{ display: 'block', color: 'red' }}>
                                                                                        {t("course_step.filling_required")}
                                                                                    </span>
                                                                                )}
                                                                            </p>

                                                                            {selected && renderSettingsString(field.key, procedure)}
                                                                        </Checkbox>
                                                                    </Form.Item>
                                                                }
                                                                forceRender={true}
                                                            >
                                                                <table className={styles.procedureSettingsTable}>
                                                                    <tbody>
                                                                        {occlusionTypeValue !== 'without' && (
                                                                            <tr>
                                                                                <th></th>
                                                                                {(targetEyeValue === 'ou' || targetEyeValue === 'od') &&
                                                                                    procedure !== ProcedureTypeEnum.BIFIXATION_RECOVERY && (
                                                                                        <th className={styles.rowLabel}>OD</th>
                                                                                    )}
                                                                                {(targetEyeValue === 'ou' || targetEyeValue === 'os') &&
                                                                                    procedure !== ProcedureTypeEnum.BIFIXATION_RECOVERY && (
                                                                                        <th className={styles.rowLabel}>OS</th>
                                                                                    )}
                                                                            </tr>
                                                                        )}

                                                                        {PROCEDURE_SETTINGS_TYPES[procedure].map((prop, ip) => {
                                                                            const settingProp = PROCEDURE_SETTING_PROPS_DICTIONARY(procedure)[prop];
                                                                            const propValues = settingProp.values;

                                                                            if (prop === 'amblyopia') {
                                                                                if (
                                                                                    !(
                                                                                        targetEyeValue === 'ou' &&
                                                                                        !!occlusionTypeValue &&
                                                                                        occlusionTypeValue !== 'without'
                                                                                    )
                                                                                ) {
                                                                                    return;
                                                                                }
                                                                            }

                                                                            return targetEyeValue !== 'ou' ? (
                                                                                <tr key={ip}>
                                                                                    <td className={styles.rowLabel}>
                                                                                        {t(`procedureSettingsWizard.${prop}.formLabel`)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={[field.name, targetEyeValue!, prop]}
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                          //   getLensValidator(
                                                                                                          //       prop,
                                                                                                          //       field.name,
                                                                                                          //       targetEyeValue,
                                                                                                          //   ),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : occlusionTypeValue === 'without' ? (
                                                                                <tr key={ip}>
                                                                                    <td className={styles.rowLabel}>
                                                                                        {t(`procedureSettingsWizard.${prop}.formLabel`)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={
                                                                                                prop === 'occlusionEye'
                                                                                                    ? [field.name, prop]
                                                                                                    : [field.name, 'ou', prop]
                                                                                            }
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                          //   getLensValidator(prop, field.name, 'ou'),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : procedure !== ProcedureTypeEnum.BIFIXATION_RECOVERY ? (
                                                                                <tr key={ip}>
                                                                                    <td className={styles.rowLabel}>
                                                                                        {t(`procedureSettingsWizard.${prop}.formLabel`)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={[field.name, 'od', prop]}
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                          //   getLensValidator(prop, field.name, 'od'),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={[field.name, 'os', prop]}
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                          //   getLensValidator(prop, field.name, 'os'),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr key={ip}>
                                                                                    <td className={styles.rowLabel}>
                                                                                        {t(`procedureSettingsWizard.${prop}.formLabel`)}
                                                                                    </td>
                                                                                    <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={
                                                                                                prop === 'occlusionEye'
                                                                                                    ? [field.name, prop]
                                                                                                    : [field.name, 'ou', prop]
                                                                                            }
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                          //   getLensValidator(prop, field.name, 'od'),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td>
                                                                                    {/* <td>
                                                                                        <Form.Item
                                                                                            className={styles.tableCellContent}
                                                                                            name={[field.name, 'os', prop]}
                                                                                            rules={
                                                                                                prop === 'lensFrom' || prop === 'lensTo'
                                                                                                    ? [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                        //   getLensValidator(prop, field.name, 'os'),
                                                                                                      ]
                                                                                                    : [
                                                                                                          {
                                                                                                              required: selected,
                                                                                                              message: t("required_field"),
                                                                                                          },
                                                                                                      ]
                                                                                            }
                                                                                        >
                                                                                            <Select
                                                                                                disabled={!selected || disabled}
                                                                                                suffixIcon={
                                                                                                    <ChevronRight className={styles.downArrow} />
                                                                                                }
                                                                                                options={propValues.map((value) => ({
                                                                                                    value,
                                                                                                    label: settingProp.valuesLocalized
                                                                                                        ? t(
                                                                                                              `procedureSettingsWizard.${prop}.values.${value}`,
                                                                                                              {
                                                                                                                  defaultValue: value,
                                                                                                              },
                                                                                                          )
                                                                                                        : value,
                                                                                                }))}
                                                                                            />
                                                                                        </Form.Item>
                                                                                    </td> */}
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </Panel>
                                                        );
                                                    })}
                                                </Collapse>
                                            )}
                                        </Form.List>
                                    </>
                                )}
                            </>
                        )}

                        <p className={styles.groupLabel}>{t("course_step.gadgets_required")}</p>
                        <Form.Item name="deviceDefinitionIDs" className={classNames(styles.checkboxGroupList, 'mb-0')}>
                            <Checkbox.Group>
                                {Object.keys(CourseDevice).map((x) => (
                                    <Checkbox key={x} value={x} disabled={true}>
                                        {t('consultationSession.courseStep.devices.' + x)}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                        </Form.Item>

                        <div className={styles.checkboxGroup}>
                            <Form.Item name="needGlassesWithoutDyo" valuePropName="checked">
                                <Checkbox> {t("course_step.glasses_without_dioptres")}</Checkbox>
                            </Form.Item>
                        </div>

                        {needGlassesWithoutDyoValue && (
                            <Form.Item
                                label={t("course_step.size")}
                                name="glassesWithoutDyoSize"
                                rules={[{ required: true, message: t("required_field") }]}
                                className={styles.formGroup}
                            >
                                <Radio.Group className={classNames(styles.radioGroup, disabled && styles.disabled)}>
                                    <label className={classNames(styles.radio, glassesWithoutDyoSizeValue === 's' && styles.selected)}>
                                        S <Radio value="s" className="d-none"></Radio>
                                    </label>
                                    <label className={classNames(styles.radio, glassesWithoutDyoSizeValue === 'm' && styles.selected)}>
                                        M <Radio value="m" className="d-none"></Radio>
                                    </label>
                                    <label className={classNames(styles.radio, glassesWithoutDyoSizeValue === 'l' && styles.selected)}>
                                        L <Radio value="l" className="d-none"></Radio>
                                    </label>
                                </Radio.Group>
                            </Form.Item>
                        )}

                        {!disabled && (
                            <Button className="w-100 mt-4" type="primary" htmlType="submit" disabled={!isFormValid}>
                                {t("save")}
                            </Button>
                        )}
                    </>
                )}
            </Form>
        </div>
    );
};

const getProceduresForCourse = (course: CoursePackageType): IProcedureFormItem[] => {
    return (
        CourseProceduresList[course].map((x) => ({
            selected: true,
            procedure: x,

            // we set for all, and filter it before submit
            ou: GET_DEFAULT_PROCEDURE_PARAMS(x),
            od: GET_DEFAULT_PROCEDURE_PARAMS(x),
            os: GET_DEFAULT_PROCEDURE_PARAMS(x),
        })) || []
    );
};

function sortObjectKeys(obj: any) {
    const sortedKeys = Object.keys(obj).sort();
    const sortedObj: any = {};
    for (const key of sortedKeys) {
        sortedObj[key] = obj[key];
    }
    return sortedObj;
}

export const fixProcedureSettingsTypes = (settings: Partial<ProcedureSettingsValueType>): Partial<ProcedureSettingsValueType> => {
    if (!settings) {
        return {};
    }
    const copy: any = sortObjectKeys({ ...settings });

    Object.keys(copy)
        .sort()
        .forEach((key) => {
            if (!(key === 'lensFrom' || key === 'lensTo')) {
                copy[key] = toNumber(copy[key]);
            }
        });

    return copy;
};
