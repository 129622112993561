//eslint-disable-next-line
export const PHONE_VALIDATION = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;

export const TOKEN_BEFORE_PARAM = 'token';

export enum StorageKeys {
    'currentPatientId' = 'currentPatientId',
    'currentDoctorId' = 'currentDoctorId',
    'emailAfterToken' = 'emailAfterToken',
    'emailBeforeToken' = 'emailBeforeToken',
}

export const AUDIO_SAMPLE_LINK = 'https://www.fesliyanstudios.com/play-mp3/387';

export const defaultScreenSettings = {
    diagonal: '',
    colors: {
        red: 150,
        blue: 0,
        green: 150,
    },
    distance: '100',
    videoAudio: {
        audioInput: undefined,
        audioOutput: undefined,
        video: undefined,
    },
};
// Response error codes:
export const ACCOUNT_ALREADY_EXISTS = 'ACCOUNT_ALREADY_EXISTS';
export const EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE';
export const PHONE_ALREADY_IN_USE = 'PHONE_ALREADY_IN_USE';
export const INVALID_SMS_CODE = 'INVALID_SMS_CODE';
export const FEIGN_REQUEST_FAILED = 'FEIGN_REQUEST_FAILED';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const APPROVE_ACTION_INVALID_CODE = 'APPROVE_ACTION_INVALID_CODE';
export const APPROVE_ACTION_STILL_ACTIVE = 'APPROVE_ACTION_STILL_ACTIVE';

export const REACT_APP_SUPPORT_PHONE = process.env.REACT_APP_SUPPORT_PHONE;

export enum OrderTypes {
    plan = 'plan',
    consultation = 'consultation',
}

export const END_DAY_TIME = 86399000;

export const ORGANIZATION_ID = 'clinic.oko';
