import { useEffect, useRef, useState } from 'react';

// import { lazyLoadScriptService } from '@shared/utils';
import Icon from '@ant-design/icons';
import { AlertIconOutlined, CloseBigBtn, CrossIcon, PdfFile, Preloader } from '@icons';
import { convertBlobToBase64 } from '@utils/base64';
import { CertificateItem, getCertificates, sign } from '@utils/crypto-helpers';
import { Button, Modal, Select, Space, message } from 'antd';
import classNames from 'classnames';
// import '../../vendors/cadesplugin_api';
import styles from './FileSigning.module.scss';
import {useTranslation} from "react-i18next";

declare let cadesplugin: any;

interface IFileSigningProps {
    disabled?: boolean;
    fileNameLabel: string;

    requestFile: (certificate: any) => Promise<any>;
    sendSignature: (value: Blob) => Promise<any>;
    ok: () => void;
    close: () => void;
}

export const FileSigning = ({ requestFile, sendSignature, ok, close, disabled, fileNameLabel }: IFileSigningProps) => {
    const {t} = useTranslation();
    const [signInProgress, setSignInProgress] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [displayPreview, setDisplayPreview] = useState<boolean>(false);
    const [signed, setSigned] = useState<boolean>(false);
    const [file, setFile] = useState<Blob>();
    const [fileUrl, setFileUrl] = useState<string>();
    const [error, setError] = useState<string>();
    const [selectedCertificate, setSelectedCertificate] = useState<number>(-1);
    const [certificates, setCertificates] = useState<any[]>([]);
    const [certificateOptions, setCertificateOptions] = useState<any[]>([{ value: -1, label: t("file_signing.select_certificate_s") }]);
    const modalRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (showModal) {
            const loadCades = async () => {
                const cades = (await import('../../../../../../../../vendors/cadesplugin_api')).default;
                cades;
                cadesplugin.then(
                    () => {
                        console.log('>>> ******* cadesplugin LOADED *******');
                        loadCertificates();
                    },
                    (error: string) => {
                        setLoading(false);
                        setError(error);
                        console.error('>>> ******* cadesplugin ERROR *******');
                    },
                );
            };
            loadCades();
        }
    }, [showModal]);

    useEffect(() => {
        const escapeHandler = ({ key }: { key: string }) => {
            if (key === 'Escape') {
                hidePreview();
            }
        };
        document.body.addEventListener('keyup', escapeHandler);
        return () => {
            document.body.removeEventListener('keyup', escapeHandler);
        };
    }, []);

    useEffect(() => {
        setCertificateOptions([{ value: -1, label: t("file_signing.select_certificate_s") }, ...certificates.map((c, i) => ({ value: i, label: c.info }))]);
    }, [certificates]);

    const selectCertificate = (certificate: any) => {
        setSelectedCertificate(certificate);
    };

    const receiveFile = () => {
        if (selectedCertificate === -1 || loading) {
            return;
        }
        setLoading(true);
        requestFile(certificates[selectedCertificate])
            .then((res) => {
                const blob = new Blob([res.data], {
                    type: 'application/pdf',
                });
                setShowModal(false);
                setFile(res.data);
                convertBlobToBase64(blob).then((r) => {
                    setFileUrl(r);
                });
            })
            .catch((err) => {
                console.error('>>>', err);
                setError(err.response?.data.message || t("file_signing.generate_report_error"));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const signFile = async () => {
        const certificate = certificates[selectedCertificate];
        if (!file) {
            return;
        }
        setSignInProgress(true);

        const signData = await sign(certificate.value, fileUrl as string);

        sendSignature(signData as Blob)
            .then((res) => {
                message.success(t("file_signing.signing_success"));
                setSigned(true);
                ok();
            })
            .catch((err) => {
                message.error(t("file_signing.cant_signing"));
            })
            .finally(() => {
                setSignInProgress(false);
            });
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleDelete = () => {
        setShowModal(false);
        setLoading(false);
        setFileUrl(undefined);
        setFile(undefined);
        setError(undefined);
    };

    const loadCertificates = () => {
        setLoading(true);
        getCertificates()
            .then((certificates: CertificateItem[]) => {
                setCertificates(certificates);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const preview = () => {
        setDisplayPreview(true);
    };

    const hidePreview = () => {
        setDisplayPreview(false);
    };

    return (
        <>
            {fileUrl ? (
                <>
                    <Space size={24} className={classNames(styles.document, 'w-100')} onClick={preview}>
                        <PdfFile />
                        <strong>{fileNameLabel}</strong>
                        <Button type="text" onClick={handleDelete}>
                            <CrossIcon />
                        </Button>
                    </Space>
                    {displayPreview && (
                        <div className={styles.previewMask} onClick={hidePreview}>
                            <iframe src={`data:application/pdf;base64,${fileUrl}`} />
                            <CloseBigBtn className={styles.closePreviewBtn} />
                        </div>
                    )}
                    {signed ? (
                        <Button className="w-100" type="primary" onClick={close}>
                            {disabled ? t("file_signing.end_appointment") : t("file_signing.save_data_and_end_appointment")}
                        </Button>
                    ) : (
                        <div>
                            <Space size={16} className={classNames(styles.warning)}>
                                <AlertIconOutlined className={styles.alertIco} />
                                <div>{t("file_signing.after_signing_attention")}</div>
                            </Space>
                            <Button className="w-100" type="primary" onClick={signFile} disabled={signInProgress}>
                                {signInProgress ? t("file_signing.signing_progress") : t("file_signing.sign_report")}
                            </Button>
                        </div>
                    )}
                </>
            ) : (
                <Button className="w-100" type="primary" onClick={() => setShowModal(true)} disabled={disabled}>
                    {t("file_signing.generate_report_button")}
                </Button>
            )}

            <Modal
                width={592}
                title={t("file_signing.select_certificate")}
                okText={t("file_signing.load_doc_to_sign")}
                cancelText={t("file_signing.signing_cancel")}
                onOk={receiveFile}
                onCancel={closeModal}
                forceRender
                open={showModal}
            >
                {loading && (
                    <Space className="mb-3">
                        <Icon component={Preloader} spin /> {t("file_signing.loading_icon")}
                    </Space>
                )}
                {error && <div className={classNames('mb-3', styles.error)}>{error}</div>}

                <div className="p-32" ref={modalRef}>
                    <Select
                        disabled={loading || !!error}
                        getPopupContainer={() => modalRef?.current || document.body}
                        className="w-100"
                        value={selectedCertificate}
                        onChange={selectCertificate}
                        options={certificateOptions}
                    />
                </div>
            </Modal>
        </>
    );
};
