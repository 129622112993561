import { FileSafeDTOBucketType, uploadFile, UserDocumentDTOType } from '@api/documentServiceAPI';
import {
    AppointmentDTOAppointmentType,
    EncounterDetailDTOPaymentStatus,
    EncounterDetailDTOStatus,
    EncounterDTODetails,
    obtainEncounterDetails,
    obtainEncounterDetailsByAppointmentId,
    UserDocumentDTO,
} from '@api/mainServiceAPI';
import { getCashReceipt } from '@api/paymentServiceAPI';
import { CountdownTimer } from '@components';
import { AppointmentStatus } from '@enums';
import { AlertIconOutlined, CalendarIcon, CheckCircle48, ChevronLeft, DiagnosisIcon, FileAddedIcon, MoreIcon, PdfFile, UserIcon } from '@icons';
import { FilesUploadModal } from '@modals';
import { setFileViewer } from '@sliceCore';
import { selectAuthUser, selectCurrentProfile, UserRole } from '@sliceUser';
import { Logger } from '@utils/logger';
import { capitalizeText, separateFileNameAndExtension } from '@utils/utils';
import { Button, Checkbox, Col, Dropdown, Form, MenuProps, Row, Select, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { MED_TIME_BEFORE_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { Chats } from 'src/components/Chats/Chats';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import { CheckNotExistModal } from 'src/modals/CheckNotExistModal/CheckNotExistModal';
import { UploadFileExt } from 'src/modals/FilesUploadModal/FilesUploadModal';
import { getEncounterStatus } from 'src/pages/CallCenterManager/CallCenterManagerAppointmentsPage/dictionary';
import styles from './NurseAppointmentItemPage.module.scss';

interface IChangeReasonForm {
    lowVision: boolean;
    reducedDistanceVision: boolean;
    reducedNearVision: boolean;
    exotropia: boolean;
    convergentStrabismus: boolean;
    description: string;
}

export const NurseAppointmentItemPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const currentUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);

    const [encounter, setEncounter] = useState<EncounterDTODetails>();
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showEditReason, setShowEditReason] = useState(false);
    const [showCheckNotExistModal, setShowCheckNotExistModal] = useState(false);

    useEffect(() => {
        retrieveEncounterDetails();
    }, []);

    const retrieveEncounterDetails = () => {
        if (params.appointmentId) {
            obtainEncounterDetailsByAppointmentId(params.appointmentId).then((result) => {
                if (result.data) {
                    setEncounter(result.data);
                }
            });
        } else if (params.encounterId) {
            obtainEncounterDetails(params.encounterId).then((result) => {
                if (result.data) {
                    setEncounter(result.data);
                }
            });
        }
    };

    const onBackToAllAppointments = () => {
        navigate('/appointments');
    };

    // todo payment logic after backend
    const items: MenuProps['items'] = [
        {
            label: <a onClick={() => console.log('Copy payment link')}>{t('appointment.paymentOptions.copyLink')}</a>,
            key: '0',
        },
        {
            label: <a onClick={() => console.log('Send payment on email')}>{t('appointment.paymentOptions.sendEmail')}</a>,
            key: '1',
        },
    ];

    // todo after payment
    const onPaymentDetails = () => {
        navigate('/');
    };

    const onPatientDetails = (patientId?: string) => {
        navigate('/medcard/' + patientId);
    };

    // todo open course for patient
    const onCourseDetails = () => {
        console.log('open course details');
    };

    //TODO remove redundant field
    const openDocument = (doc: UserDocumentDTO & { s3Key?: string }) => {
        const key = doc.s3Key || doc.s3key;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${doc.name}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                }),
            );
        }
    };

    // todo upload documents modal?
    const onUploadDocuments = () => {
        setShowFileUploadModal(true);
    };

    // todo change appointment
    const handleChangeDate = () => {
        navigate('/');
    };

    // const onCancelConsultation = () => {
    //     endEncounterEvent({
    //         encounterFhirId: encounter?.encounterDetailDTO?.encounterFhirId,
    //         managerId: currentUser?.id,
    //         cancel: true,
    //     }).then((result) => {
    //         if (result) {
    //             retrieveEncounterDetails();
    //         }
    //     });
    // };

    const uploadDocuments = (files: UploadFileExt[]) => {
        if (files.length) {
            files.forEach((f, i) => {
                if (f.done || f.loading) {
                    return;
                }
                f.loading = true;

                uploadFile(
                    {
                        fhirId: encounter?.encounterDetailDTO?.patientFhirId,
                        type: f.docType,
                        file: f as RcFile,
                        typeDescription: f.otherType,
                        ...separateFileNameAndExtension(f.name),
                    },
                    { headers: { 'Content-Type': 'multipart/form-data' } },
                )
                    .then((res: any) => {
                        f.done = true;
                        f.loading = false;
                        f.uuid = res.data.uuid;
                    })
                    .catch((err) => {
                        Logger.error(err);
                    });
            });
        }
    };

    const onEditDiagnosis = () => {
        setShowEditReason(true);
    };

    const [changeReasonForm] = Form.useForm();

    const { validateForm } = useFormValidation(changeReasonForm);

    const validate = () => {
        const fieldsToCheck = ['lastName', 'firstName', 'mobile', 'email', 'role'];
        validateForm(fieldsToCheck);
    };

    const changeReason = (formData: IChangeReasonForm) => {
        if (!changeReasonForm) {
            return;
        }
        changeReasonForm.validateFields();
        setTimeout(() => {
            if (!changeReasonForm.getFieldsError().find(({ errors }: { errors: string[] }) => errors.length > 0)) {
                // todo backend change reason
            }
        });
    };

    const onThreatmentResultsDetails = (patientId?: string) => {
        console.log('Open results.');
    };

    const encounterStatus = getEncounterStatus(encounter?.encounterDetailDTO?.status, encounter?.encounterDetailDTO?.paymentStatus);

    const getReceipt = () => {
        getCashReceipt({
            encounterId: encounter?.encounterDetailDTO?.encounterFhirId,
        }).then((res) => {
            if (res.data) {
                window.open(res.data[0]);
            }
        });
    };

    const onConnectToConsultation = () => {
        navigate('/appointment?appointmentId=' + encounter?.encounterDetailDTO?.appointmentFhirId);
    };

    return encounter ? (
        <div className={styles.wrapper}>
            <div className="d-flex justify-content-between">
                <div className={styles.titleContainer}>
                    <Button onClick={() => onBackToAllAppointments()} className="backBtn">
                        <ChevronLeft className="transparent" />
                    </Button>
                    <p className={styles.title}>
                        {encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'diagnostic'
                            ? t(`patientAppointmentItemPage.title.diagnosticPlanDefinition.${encounter?.partOf}`)
                            : encounter?.encounterDetailDTO?.appointmentType && encounter?.encounterDetailDTO?.appointmentType === 'course'
                            ? t(`patientAppointmentItemPage.title.coursePlanDefinition.${encounter?.partOf}`)
                            : t(`patientAppointmentItemPage.title.${encounter?.encounterDetailDTO?.appointmentType}`)}
                    </p>
                </div>
            </div>

            <Row gutter={24}>
                <Col md={16} sm={24} xs={24}>
                    <Col md={24} sm={24} xs={24}>
                        <div className={styles.eventBottomBlock}>
                            {/* Encounter Field */}
                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div>
                                            <CalendarIcon />
                                        </div>
                                        <div>
                                            <p>{t(`appointment.dateTime`)}</p>
                                            <div className="d-flex">
                                                <p className={styles.dateText}>
                                                    {encounter?.encounterDetailDTO?.dateTime &&
                                                        format(new Date(encounter?.encounterDetailDTO?.dateTime), 'dd.MM.yyyy HH:mm')}
                                                </p>
                                                <div className={styles.statusField}>
                                                    <EncounterStatusBadge
                                                        encounterStatus={encounter?.encounterDetailDTO?.status}
                                                        paymentStatus={encounter?.okoEventDTO?.paymentStatus}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {encounter?.encounterDetailDTO?.dateTime &&
                                        encounterStatus === AppointmentStatus.confirmed &&
                                        encounter?.encounterDetailDTO?.practitionerFhirId === currentProfile?.fhirId && (
                                            <CountdownTimer
                                                timeX={new Date(encounter?.encounterDetailDTO?.dateTime)}
                                                before={MED_TIME_BEFORE_START_OF_SESSION}
                                                keepAfterX={true}
                                                render={(timeLabel: string) => (
                                                    <div className="d-flex">
                                                        {timeLabel && (
                                                            <Tag className={styles.startsOver}>
                                                                {t('docMainPage.startsOver')} <strong className="h6">{timeLabel}</strong>
                                                            </Tag>
                                                        )}
                                                        <Button type="primary" onClick={() => onConnectToConsultation()}>
                                                            {t('appointment.connect')}
                                                        </Button>
                                                    </div>
                                                )}
                                            ></CountdownTimer>
                                        )}
                                </div>

                                {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                    <div className={styles.cancelReasonBlock}>
                                        <AlertIconOutlined />
                                        <p>{t('appointment.cancelReason')}</p>
                                        <p>
                                            {t(
                                                'enums.encounterChangeReason.' + encounter?.encounterDetailDTO.changeReason?.at(-1)?.type,
                                                t('appointment.encounterChangeReason.no_data'),
                                            )}
                                        </p>
                                    </div>
                                )}
                            </div>

                            {/* Payment Field */}
                            {encounter?.encounterDetailDTO?.paymentStatus === EncounterDetailDTOPaymentStatus.completed && (
                                <div className={styles.infoField}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <div className={styles.circleGreen}>
                                                <CheckCircle48 />
                                            </div>
                                            <div>
                                                <p>
                                                    {capitalizeText(
                                                        t(`callCenterManager.paymentStatuses.${encounter?.encounterDetailDTO?.paymentStatus}`),
                                                    )}
                                                </p>
                                                <div className="d-flex">
                                                    {/*<p className={styles.dateTextSmall}>
                                                        {encounter?.encounterDetailDTO?.paymentDate &&
                                                            dayjs(encounter?.encounterDetailDTO?.paymentDate).format('DD.MM.YYYY')}
                                                    </p>*/}
                                                    <div className={styles.statusField}>
                                                        <NavLink className={styles.checkButton} to="" onClick={() => getReceipt()}>
                                                            {t('appointment.seeCheck')}
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {encounter?.encounterDetailDTO?.status == EncounterDetailDTOStatus.cancelled && (
                                            <p className={styles.returnMoneyText}>{t('appointment.returnMoneyText')}</p>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Patient Field */}

                            <div className={styles.infoField}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex">
                                        <div className={styles.avatar}>
                                            {encounter?.encounterDetailDTO?.patientPhoto ? (
                                                <img src={encounter?.encounterDetailDTO?.patientPhoto} />
                                            ) : (
                                                <UserIcon />
                                            )}
                                        </div>
                                        <div>
                                            <p
                                                onClick={() => onPatientDetails(encounter?.encounterDetailDTO?.patientFhirId)}
                                                className={styles.nameText}
                                            >
                                                {encounter?.encounterDetailDTO?.patientName}
                                            </p>
                                            <div className="d-flex">
                                                <p> {dayjs(encounter?.encounterDetailDTO?.patientBirthDay).fromNow(true)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    {/* First Consultation Block */}
                    {encounter?.encounterDetailDTO?.appointmentType == AppointmentDTOAppointmentType.initial_consultation && (
                        <Col md={24} sm={24} xs={24}>
                            <h3 className={styles.reasonText}>{t('appointment.reason')}</h3>
                            {showEditReason ? (
                                <div className={styles.changeReasonBlock}>
                                    <Form
                                        form={changeReasonForm}
                                        onFinish={changeReason}
                                        name="changeReasonForm"
                                        layout="vertical"
                                        wrapperCol={{ span: 24 }}
                                        initialValues={{
                                            diagnosis: '',
                                            lowVision: false,
                                            reducedDistanceVision: false,
                                            reducedNearVision: false,
                                            exotropia: false,
                                            convergentStrabismus: false,
                                            description: '',
                                        }}
                                        autoComplete="off"
                                        requiredMark={false}
                                        onChange={validate}
                                    >
                                        <Row gutter={24}>
                                            <Col md={24} sm={24} xs={24}>
                                                <p className={styles.fieldLabel}>{t('appointment.diagnosis')}</p>
                                            </Col>
                                            <Col md={24} sm={24} xs={24} className="mt-1">
                                                <Form.Item name="diagnosis">
                                                    <Select
                                                        disabled
                                                        options={[
                                                            {
                                                                label:
                                                                    encounter?.encounterDetailDTO?.diagnosis &&
                                                                    encounter?.encounterDetailDTO?.diagnosis
                                                                        .map((item) =>
                                                                            t(`consultationSession.patientInfoStep.preliminaryDiagnosis.${item}`),
                                                                        )
                                                                        ?.join(', '),
                                                                value: '',
                                                            },
                                                        ]}
                                                        value={''}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col md={24} sm={24} xs={24} className="mt-4">
                                                <p className={styles.fieldLabel}>{t('appointment.complaints.title')}</p>
                                            </Col>
                                            <Col md={24} sm={24} xs={24} className="mt-4">
                                                <Form.Item name="lowVision" className={styles.complaintField}>
                                                    <Checkbox /> <p>{t(`appointment.complaints.lowVision`)}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col md={24} sm={24} xs={24}>
                                                <Form.Item name="reducedDistanceVision" className={styles.complaintField}>
                                                    <Checkbox /> <p>{t(`appointment.complaints.reducedDistanceVision`)}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col md={24} sm={24} xs={24}>
                                                <Form.Item name="reducedNearVision" className={styles.complaintField}>
                                                    <Checkbox /> <p>{t(`appointment.complaints.reducedNearVision`)}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col md={24} sm={24} xs={24}>
                                                <Form.Item name="exotropia" className={styles.complaintField}>
                                                    <Checkbox /> <p>{t(`appointment.complaints.exotropia`)}</p>
                                                </Form.Item>
                                            </Col>
                                            <Col md={24} sm={24} xs={24}>
                                                <Form.Item name="convergentStrabismus" className={styles.complaintField}>
                                                    <Checkbox /> <p>{t(`appointment.complaints.convergentStrabismus`)}</p>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={24}>
                                            <Col md={24} sm={24} xs={24}>
                                                <Form.Item name="description" className={styles.descriptionField}>
                                                    <TextArea />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div key="block" className="d-flex">
                                            <Button onClick={() => setShowEditReason(false)} key="back">
                                                {t('appointment.cancel')}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    changeReasonForm.submit();
                                                    setShowEditReason(false);
                                                }}
                                                key="cancel"
                                                type="primary"
                                                style={{ marginLeft: '16px' }}
                                            >
                                                {t('appointment.saveChanges')}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            ) : (
                                <div className={styles.reasonBlock}>
                                    <div className={styles.infoField}>
                                        <div className="d-flex">
                                            <div>
                                                <DiagnosisIcon />
                                            </div>
                                            <div>
                                                <p>
                                                    {t("enums.nurse.diagnostic.diagnosis")}:{' '}
                                                    {encounter.encounterDetailDTO.diagnosis
                                                        ?.map((x) => t('enums.preliminaryDiagnosis.' + x))
                                                        .join(', ') || t("enums.nurse.diagnostic.dont_know")}
                                                </p>

                                                {!!encounter?.encounterDetailDTO.reasonCodes?.length && (
                                                    <ul>
                                                        {encounter?.encounterDetailDTO.reasonCodes.map((x, i) => (
                                                            <li key={i}>{t('consultationSession.patientInfoStep.reasonCodes.' + x)}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                                <p>{encounter.encounterDetailDTO?.comment}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Col>
                    )}

                    {/* Completed: Documents and Recommendation */}
                    {encounterStatus === AppointmentStatus.completed && encounter?.documents && encounter?.documents?.length > 0 && (
                        <>
                            {encounter.documents.filter((item) => item.type === UserDocumentDTOType.DISCHARGE_SUMMARY).length > 0 && (
                                <Col md={24} sm={24} xs={24}>
                                    <h3 className={styles.loadedDocumentsText}>{t('appointment.discharge')}</h3>
                                    <div className={styles.documentsBlockCompleted}>
                                        {encounter.documents
                                            .filter((item) => item.type === UserDocumentDTOType.DISCHARGE_SUMMARY)
                                            .map((item) => (
                                                <div className={styles.infoField} key={item.documentFhirId}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <div>
                                                                <PdfFile />
                                                            </div>
                                                            <div>
                                                                <h5 className={styles.documentLabel}>
                                                                    {t(`appointment.documentTypes.${item.type}`)}
                                                                </h5>
                                                                <p>{item.name}</p>
                                                            </div>
                                                        </div>
                                                        <Button type="ghost" onClick={() => openDocument(item)} className={styles.documentButton}>
                                                            {t('appointment.download')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Col>
                            )}
                            {/* {encounter.documents.filter((item) => item.type !== UserDocumentDTOType.DISCHARGE_SUMMARY).length > 0 && (
                                <Col md={24} sm={24} xs={24}>
                                    <h3 className={styles.loadedDocumentsText}>{t('appointment.recommendation')}</h3>
                                    <div className={styles.recommendationBlockCompleted}>
                                        {encounter.documents
                                            .filter((item) => item.type !== UserDocumentDTOType.DISCHARGE_SUMMARY)
                                            .map((item) => (
                                                <div className={styles.infoField} key={item.documentFhirId}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className="d-flex" style={{ alignItems: 'center' }}>
                                                            <div>
                                                                <EyeIcon />
                                                            </div>
                                                            <div>
                                                                <h5 className={styles.documentLabel}>
                                                                    {t(`appointment.documentTypes.${item.type}`)}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <Button
                                                            type="ghost"
                                                            onClick={() => onPatientDetails(encounter.encounterDetailDTO?.patientFhirId)}
                                                            className={styles.doctorDetailsButton}
                                                        >
                                                            {t('appointment.courseDetails')}
                                                            <TriangleRight />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </Col>
                            )} */}
                        </>
                    )}

                    {/* NotCompleted: Documents */}
                    {encounterStatus !== AppointmentStatus.completed && encounterStatus === AppointmentStatus.cancelled && (
                        <Col md={24} sm={24} xs={24}>
                            <h3 className={styles.loadedDocumentsText}>{t('appointment.loadedDocuments')}</h3>
                            {encounter?.documents && encounter?.documents?.length > 0 && (
                                <>
                                    <div className={styles.documentsBlock}>
                                        {encounter.documents.map((item) => (
                                            <div className={styles.infoField} key={item.documentFhirId}>
                                                <div className="d-flex">
                                                    <div>
                                                        <FileAddedIcon />
                                                    </div>
                                                    <div>
                                                        <h5 className={styles.documentLabel}>{t(`appointment.documentTypes.${item.type}`)}</h5>
                                                        <p>{item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                            {/* todo check upload documents */}

                            <Button style={{ marginTop: '16px' }} onClick={() => onUploadDocuments()}>
                                {t('appointment.loadDocuments')}
                            </Button>
                        </Col>
                    )}
                </Col>
                <Col md={8} sm={24} xs={24}>
                    <Chats
                        currentUserRole={UserRole.Nurse}
                        currentUserId={currentProfile?.fhirId}
                        chats={encounter?.chats}
                        headerInfo={t("enums.nurse.chat.questions")}
                        noDoctorPatientMessagesLabel={
                            dayjs(encounter?.encounterDetailDTO?.dateTime) < dayjs()
                                ? t("enums.nurse.chat.chat_not_active")
                                : t("enums.nurse.chat.chat_not_allowed")
                        }
                    />
                </Col>
            </Row>

            {showFileUploadModal && (
                <FilesUploadModal
                    onCancel={() => setShowFileUploadModal(false)}
                    onSuccess={(files) => {
                        // setData({ ...data, filesToUpload: files });
                        uploadDocuments(files);
                        setShowFileUploadModal(false);
                    }}
                    files={[]}
                    showModal={showFileUploadModal}
                />
            )}

            {showCheckNotExistModal && <CheckNotExistModal visible={showCheckNotExistModal} onClose={() => setShowCheckNotExistModal(false)} />}
        </div>
    ) : null;
};
