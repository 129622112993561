import { approvePaymentByManager } from '@api/paymentServiceAPI';
import { Button, Modal } from 'antd';
import { t } from 'i18next';
import styles from './PaymentDirectlyConfirmModal.module.scss';

export interface IPaymentDirectlyConfirmModalProps {
    visible: boolean;
    encounterId: string | undefined;
    onClose: () => void;
}

export const PaymentDirectlyConfirmModal = ({ visible, onClose, encounterId }: IPaymentDirectlyConfirmModalProps) => {
    const approvePaymentDirectly = (): void => {
        approvePaymentByManager({ resourceDefinitionId: encounterId }).then((result) => {
            location.reload();
        });
    };

    return visible ? (
        <Modal
            className={styles.paymentDirectlyConfirmModal}
            width={592}
            open={visible}
            onCancel={onClose}
            closable={false}
            title={t('modals.paymentDirectly.confirmTitle')}
            footer={[
                <Button onClick={onClose} key="cancel">
                    {t('modals.buttons.cancel')}
                </Button>,
                <Button onClick={() => approvePaymentDirectly()} key="confirm" type="primary">
                    {t('modals.buttons.confirm')}
                </Button>,
            ]}
        >
            <>
                <p style={{ marginTop: 0 }}>
                    {t("payment.directly_payment")}
                </p>
            </>
        </Modal>
    ) : null;
};
