import { ConsultationSlotDTO, ConsultationSlotDTOReasonCodesItem, ConsultationSlotDTOServiceType } from '@api/mainServiceAPI';
import { FileToSignIcon } from '@icons';
import { selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Button, Checkbox, Col, Row } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IStepProps } from '../../ConsultationSignUpPage';
import styles from './ContractStep.module.scss';
import { UserDirectDocumentDTOType } from '@api/documentServiceAPI';
import useOpenFile from 'src/hooks/open-file.hook';
import {useTranslation} from "react-i18next";

interface IContractStep {
    onChangeStep: (step: number) => void;
    onSubmit: (formValue: ConsultationSlotDTO) => void;
    isContractSigned: boolean;
}
export const ContractStep = ({ onChangeStep, data, isContractSigned, onSubmit, setData }: IStepProps & IContractStep) => {
    const {t} = useTranslation();
    const authUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);
    const openFile = useOpenFile();

    const [contractAgree, setContractAgree] = useState(false);
    const [serviceAgree, setServiceAgree] = useState(false);

    useEffect(() => {
        setContractAgree(data?.contractAgree || false);
        setServiceAgree(data?.serviceAgree || false);
    }, []);

    const submit = () => {
        const reasonsArr = Object.keys(ConsultationSlotDTOReasonCodesItem);
        const slotIds = Object.values(data?.time?.practitionersMap as object);
        const slotId = slotIds.length === 1 ? slotIds[0] : data?.time?.practitionersMap[data.doctor?.id as string];
        if (!(slotId && data?.doctor && data?.time)) {
            return;
        }
        const payload: ConsultationSlotDTO = {
            patientId: currentProfile?.fhirId,
            slotId,
            serviceType: ConsultationSlotDTOServiceType.consultation,
            contractAgree,
            serviceAgree,
            reasonCodes: data?.reasons?.map((r, i) => (r ? reasonsArr[i] : undefined)).filter((x) => x) as ConsultationSlotDTOReasonCodesItem[],
            reasonDescription: data?.customReason,
        };
        const files: string[] | undefined = data.filesToUpload?.map((f) => f.uuid as string).filter((x) => x);
        if (files?.length) {
            payload.files = files;
        }

        onSubmit(payload);
        setData({ ...data, serviceAgree, contractAgree });
        onChangeStep(4);
    };

    const renderServiceAgreeSigner = () => {
        let signer: any = currentProfile?.userProfileDetailsDTO;
        if (!signer) {
            return null;
        }

        const age = dayjs().diff(dayjs(signer.birthDate!), 'year');
        if (age < 16) {
            signer = authUser;
        }

        return (
            <>
                {t("consultation_sign_up.contract_step.approve_medical", {lastName: signer.lastName, firstName: signer.firstName, middleName: signer.middleName ? ` ${signer.middleName}` : ''})}
            </>
        );
    };

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{t("consultation_sign_up.contract_step.sign_required_documents")}</p>

            <div className="mb-4">
                <Row align="middle" gutter={24} className={styles.document}>
                    <Col span={2}>
                        <FileToSignIcon />
                    </Col>
                    <Col span={19}>{t("consultation_sign_up.contract_step.medical_services_contract")}</Col>
                    <Col className="d-flex justify-content-end" span={3}>
                        <Button 
                            type='link' 
                            className={styles.link} 
                            onClick={() => openFile(UserDirectDocumentDTOType.MEDICAL_SERVICE_CONTRACT, true)}
                        >
                            {t("consultation_sign_up.contract_step.download")}
                        </Button>
                    </Col>
                </Row>
                <Row align="middle" gutter={24} className={styles.document}>
                    <Col span={2}>
                        <FileToSignIcon />
                    </Col>
                    <Col span={19}>{t("consultation_sign_up.contract_step.medical_services_consent")}</Col>
                    <Col className="d-flex justify-content-end" span={3}>
                        <Button 
                            type='link' 
                            className={styles.link} 
                            onClick={() => openFile(UserDirectDocumentDTOType.MEDICAL_SERVICE_CONSENT, true)}
                        >
                            {t("consultation_sign_up.contract_step.download")}
                        </Button>
                    </Col>
                </Row>
            </div>

            <label className="d-flex mb-4 align-items-center">
                <Checkbox
                    disabled={isContractSigned}
                    checked={contractAgree}
                    onChange={() => setContractAgree(!contractAgree)}
                    className="m-0 me-3"
                />
                <p className={styles.checkbox}>
                    {t("consultation_sign_up.contract_step.approve_contract", {lastName:  authUser?.lastName, firstName: authUser?.firstName, middleName:  authUser?.middleName ? ` ${authUser?.middleName}` : ''})}
                </p>
            </label>
            <label className="d-flex align-items-center">
                <Checkbox disabled={isContractSigned} checked={serviceAgree} onChange={() => setServiceAgree(!serviceAgree)} className="m-0 me-3" />
                <p className={styles.checkbox}>{renderServiceAgreeSigner()}</p>
            </label>

            <div className={styles.divider} />

            <Button disabled={!contractAgree || !serviceAgree} onClick={submit} type="primary" size="large">
                {t("consultation_sign_up.contract_step.sign_documents")}
            </Button>
        </div>
    );
};
