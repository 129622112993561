import {
    EntriesAssignmentsDTO,
    EntriesAssignmentsDTOPaymentStatus,
    GetEntriesAssignmentsParams,
    getEntriesAssignments,
    obtainAllEncounterEventForManager,
} from '@api/mainServiceAPI';
import { SearchIcon } from '@icons';
import { Button, Col, Input, Row, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { PaymentDirectlyConfirmModal } from 'src/modals/PaymentDirectlyConfirmModal/PaymentDirectlyConfirmModal';
import styles from './CallCenterManagerAllCoursesPage.module.scss';

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const CallCenterManagerAllCoursesPage = () => {
    const { t } = useTranslation();

    const [filteredAssignmentsList, setFilteredAssignmentsList] = useState<EntriesAssignmentsDTO[]>([]);
    const [activeCount, setActiveCount] = useState<number>();

    const [filterQuery, setFilterQuery] = useState<string | undefined>();

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    const [selectedCarePlanId, setSelectedCarePlanId] = useState<string | undefined>('');

    useEffect(() => {
        obtainAllEncounterEventForManager().then((result) => {
            if (result.data.result?.content) {
                setActiveCount(result.data.activeCount);
            }
        });
    }, []);

    useEffect(() => {
        retrieveEncountersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery]);

    const retrieveEncountersToTable = (page: number, size: number, sorter?: SorterResult<EntriesAssignmentsDTO>) => {
        const searchCriteria: GetEntriesAssignmentsParams = {
            page,
            size,
            patientName: filterQuery || '',
        };

        getEntriesAssignments(searchCriteria).then((result) => {
            if (result?.data?.content) {
                const data = result.data.content;

                setFilteredAssignmentsList(data);
                setTableParams({
                    pagination: { ...tableParams.pagination, pageSize: size, current: page + 1, total: result.data?.totalElements },
                });
            }
        });
    };

    const tableColumns: ColumnsType<EntriesAssignmentsDTO> = [
        {
            title: t('callCenterManager.courses.table.dateColumn'),
            dataIndex: 'created',
            render: (text, record) => {
                if (record.created) {
                    return <p className={styles.dateText}>{format(record.created, 'dd.MM.yyyy HH:mm')}</p>;
                }
                return '-';
            },
            sorter: false,
            key: 'created',
        },
        {
            title: t('callCenterManager.courses.table.appointmentColumn'),
            dataIndex: 'carePlanType',
            render: (text, record) => {
                if (record.carePlanType) {
                    return t(`enums.planDefinition.${record.carePlanType}`);
                }
                return '-';
            },
            sorter: false,
            key: 'carePlanType',
        },
        {
            title: t('callCenterManager.courses.table.paymentColumn'),
            dataIndex: 'paymentStatus',
            render: (text, record) => {
                return t(`callCenterManager.paymentStatuses.${record.paymentStatus}`, '-');
            },
            sorter: false,
            key: 'paymentStatus',
        },
        {
            title: t('callCenterManager.courses.table.patientColumn'),
            dataIndex: 'patientFullName',
            render: (text, record) => {
                if (record.patientFullName) {
                    return record.patientFullName;
                }
                return '-';
            },
            sorter: false,
            key: 'patientFullName',
        },
        {
            title: '',
            dataIndex: 'practitionerName',
            render: (text, record) => {
                return (
                    <div>
                        {record.paymentStatus === EntriesAssignmentsDTOPaymentStatus.completed ? (
                            <Button disabled type="primary">
                                {t('callCenterManager.courses.table.paymentCompleted')}
                            </Button>
                        ) : record.carePlanId && record.paymentStatus !== EntriesAssignmentsDTOPaymentStatus.failed ? (
                            <Button
                                className="active"
                                onClick={() => {
                                    setSelectedCarePlanId(record.carePlanId!);
                                }}
                            >
                                {t('callCenterManager.courses.table.confirmPayment')}
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                );
            },
            sorter: false,
            key: 'practitionerName',
        },
    ];

    const handleTableChange: TableProps<EntriesAssignmentsDTO>['onChange'] = (pagination, filters, sorter) => {
        retrieveEncountersToTable((pagination.current || 1) - 1, pagination?.pageSize || 10, sorter as SorterResult<EntriesAssignmentsDTO>);
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <p className={styles.title}>{t('callCenterManager.encounters')}</p>
            </div>
            <div className={styles.appointmentsNavigation}>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/all">
                    {t('callCenterManager.allAppointments.navButton')}
                </NavLink>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/events">
                    {t('callCenterManager.events.navButton')}
                    {activeCount && activeCount > 0 ? <span>{activeCount}</span> : ''}
                </NavLink>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/courses">
                    {t('callCenterManager.courses.navButton')}
                </NavLink>
            </div>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={5}>
                        <Input
                            placeholder={t('callCenterManager.filters.searchPlaceholder')}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                </Row>
            </div>
            <div className="mt-4">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.carePlanId!}
                    columns={tableColumns}
                    dataSource={filteredAssignmentsList}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    pagination={false}
                />
            </div>

            {selectedCarePlanId && (
                <PaymentDirectlyConfirmModal
                    encounterId={selectedCarePlanId}
                    visible={!!selectedCarePlanId}
                    onClose={() => {
                        setSelectedCarePlanId(undefined);
                        retrieveEncountersToTable(0, tableParams.pagination?.pageSize || 10);
                    }}
                />
            )}
        </div>
    );
};
