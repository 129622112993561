import { FileSafeDTOBucketType } from '@api/documentServiceAPI';
import {
    PatientMedicalCardDTO,
    TreatmentKitDTO,
    TreatmentKitDTOId,
    TreatmentKitDTOStatus,
    UserDocumentDTO,
    approvePatientGadget,
    getPatientMedicalCard,
} from '@api/mainServiceAPI';
import { CheckIconGreen, DocsIcon, GreyGlassesIcon, InfoIcon, PdfFile, TriangleRight } from '@icons';
import { setFileViewer } from '@sliceCore';
import { getPatientCarePlan, selectCarePlan } from '@slicePatient';
import { selectCurrentProfile } from '@sliceUser';
import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import myopiaComplect from '../../../styles/images/patient-medcard/myopia-complect.png';
import styles from './PatientMedicalRecord.module.scss';

export const PatientMedicalRecordPage = () => {
    const dispatch = useDispatch();

    const currentProfile = useSelector(selectCurrentProfile);
    const carePlan = useSelector(selectCarePlan);

    const [medcard, setMedcard] = useState<PatientMedicalCardDTO>();
    const [devicesConfirmed, setDevicesConfirmed] = useState<TreatmentKitDTO[]>();

    useEffect(() => {
        if (currentProfile?.fhirId) {
            getPatientMedicalCard(currentProfile.fhirId).then((result) => {
                if (result.data) {
                    setMedcard(result.data);
                }
            });
        }
    }, [currentProfile]);

    useEffect(() => {
        if (carePlan) {
            setDevicesConfirmed(carePlan?.treatmentKits);
        }
    }, [carePlan]);

    const onDownloadDocument = (doc: UserDocumentDTO, fileName?: string) => {
        if (doc.s3key) {
            dispatch(
                setFileViewer({
                    s3Key: doc.s3key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${fileName || doc.name || t("patient_medical_record.file")}.${doc.extension}` : (doc.name as string),
                    extension: doc.extension,
                    forceDownload: true,
                }),
            );
        }
    };

    const onConfirmGadget = (gadgetId: TreatmentKitDTOId) => {
        if (currentProfile?.fhirId) {
            approvePatientGadget(currentProfile?.fhirId, gadgetId).then((result) => {
                if (result) {
                    dispatch(
                        getPatientCarePlan({
                            patientFhirId: currentProfile.fhirId!,
                        }),
                    );
                }
            });
        }
    };

    const onOpenGadgetStore = () => {
        console.log('Open gadget store.');
    };

    return (
        <div className={styles.wrapper}>
            <h1>{t('patient.medcard.label')}</h1>
            <div className={styles.content}>
                <p className={styles.subtitle}>{t('patient.medcard.discharge')}</p>
                {!!medcard?.conclusionsWithSignatures?.length ? (
                    <>
                        <div className={styles['files-wrapper']}>
                            {medcard.conclusionsWithSignatures?.map((item) => (
                                <Row gutter={24} align="middle" className={styles['file-container']} key={item.conclusion?.documentFhirId}>
                                    <Col span={2}>
                                        <PdfFile />
                                    </Col>
                                    <Col span={20}>
                                        <p className={styles['file-name']}>
                                            {item.conclusion?.name}.{item.conclusion?.extension}
                                            {!!item.signature && (
                                                <a
                                                    className={styles.link}
                                                    onClick={() => onDownloadDocument(item.signature!, item.conclusion?.name + t("patient_medical_record.signature_e"))}
                                                >
                                                    {' '}
                                                    {t("patient_medical_record.signature")}
                                                </a>
                                            )}
                                        </p>
                                    </Col>
                                    <Col span={2}>
                                        <a className={styles.link} onClick={() => onDownloadDocument(item.conclusion!)}>
                                            {t('patient.medcard.download')}
                                        </a>
                                    </Col>
                                </Row>
                            ))}
                        </div>
                    </>
                ) : (
                    <div className={styles.empty}>
                        <DocsIcon className={styles.icon} />
                        {t("patient_medical_record.about_report_message")}
                    </div>
                )}

                <p className={styles.subtitle}>{t("patient_medical_record.oko_complex")}</p>
                {!!devicesConfirmed?.length ? (
                    <div className={styles['files-wrapper']}>
                        {devicesConfirmed?.map((item) => (
                            <Row className="mb-5" gutter={32} key={item.id}>
                                <Col span={6}>
                                    <img src={myopiaComplect} />
                                </Col>
                                <Col span={18}>
                                    <p className={styles['complect-title']}>{t(`consultationSession.courseStep.devices.${item.id}`)}</p>
                                    <p className={styles['complect-description']}>
                                        {t("patient_medical_record.gadget_about_message")}
                                    </p>

                                    {item.status === TreatmentKitDTOStatus.PROPOSED ? (
                                        <>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className={styles.recommendation}>{t("patient_medical_record.recommended")}</div>
                                                <a className={styles['shop-link']} onClick={() => onOpenGadgetStore()}>
                                                    {t("patient_medical_record.go_to_gadget_shop")} <TriangleRight />
                                                </a>
                                            </div>
                                            <Button onClick={() => onConfirmGadget(item.id!)} type="primary">
                                                {t("patient_medical_record.confirm_availability")}
                                            </Button>
                                        </>
                                    ) : (
                                        item.status === TreatmentKitDTOStatus.APPROVED && (
                                            <div className="d-flex align-items-center">
                                                <CheckIconGreen className="me-3" />
                                                <p className={styles['complect-checked-name']}>{t("patient_medical_record.availability_confirmed")}</p>
                                            </div>
                                        )
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </div>
                ) : (
                    <div className={styles.empty}>
                        <GreyGlassesIcon className={styles.icon} />
                        {t("patient_medical_record.gadgets_title")}
                    </div>
                )}
                {!!devicesConfirmed?.length && (
                    <div className="d-flex align-items-center">
                        <InfoIcon className="me-2" />
                        <p className={styles.info}>{t('patient.medcard.ifLostOrBrokenGadget')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
