import { DropdownArrowIcon, ProfileIcon } from '@icons';
import { UserRole, logoutLocal, selectAuthUser } from '@sliceUser';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Outlet } from 'react-router-dom';
import { Footer } from '../../_general/Footer/Footer';
import styles from './ClinicAdminLayout.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const ClinicAdminLayout = () => {
    const authUser = useSelector(selectAuthUser);

    if (!authUser) {
        return null;
    }

    const dispatch = useDispatch();

    return (
        <div className={styles.wrapper}>
            <div className="sticky-top">
                <header className={styles.header}>
                    <nav className={styles['header-content']}>
                        <Link to="/">
                        <Logo />
                        </Link>
                        <div className="col-4 d-flex justify-content-center">
                            <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/settings">
                                {t("settings")}
                            </NavLink>
                            {authUser?.role === 'Clinic_administrator' ?
                                <>
                            <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/staff">
                                {t('clinicAdminLayout.header.navigators.staff')}
                            </NavLink>
                            <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/patients">
                                {t('clinicAdminLayout.header.navigators.patients')}
                            </NavLink>
                            <NavLink className={({ isActive }) => classNames(styles['nav-item'], isActive && styles.active)} to="/schedule">
                                {t('clinicAdminLayout.header.navigators.schedule')}
                            </NavLink>
                                </>
                            : ''}
                        </div>
                        <div className="col-3">
                            <Dropdown
                                overlayClassName={styles.dropdownMenu}
                                menu={{
                                    items: [
                                        {
                                            key: '4',
                                            onClick: () => dispatch(logoutLocal({isEsia: !!authUser.esia})),
                                            label: t('nurseLayout.header.labels.exit'),
                                        },
                                    ],
                                }}
                            >
                                <div className={styles.dropdownBtn}>
                                    <ProfileIcon />
                                    <div className="d-flex flex-column align-items-start ms-2">
                                        <div className="d-flex align-items-center">
                                            <p className={`${styles.dropdownBtnTitle} me-2`}>{`${authUser.lastName || t("appointment.authorRole.Administrator")} ${
                                                authUser.role === 'Clinic_administrator' ? t("clinics") : ''
                                            }`}</p>
                                            <DropdownArrowIcon />
                                        </div>
                                        <span className={styles.dropdownBtnSubtitle}>{t('clinicAdminLayout.header.labels.administrator')}</span>
                                    </div>
                                </div>
                            </Dropdown>
                        </div>
                    </nav>
                </header>
            </div>

            <section className={styles.mainContent}>
                <Outlet />
            </section>

            <Footer />
        </div>
    );
};
